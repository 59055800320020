
import React, { FC } from "react";

import { Helmet } from "react-helmet";

import Heading from "components/Heading/Heading";
import { useTranslation } from "react-i18next";

export interface PageAboutProps {
    className?: string;
}

const Impressum: FC<PageAboutProps> = ({ className = "" }) => {

    const { t } = useTranslation();

    return (
        <div
            className={`nc-PageAbout overflow-hidden relative ${className}`}
            data-nc-id="PageAbout"
        >
            <Helmet>
                <title>Impressum || DoDo Shop</title>
            </Helmet>

            <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">

                <h1><strong>{t("impressum")}</strong></h1>
                <br />
                Max Mustermann
                <br />
                MusterGeschäft<br />
                Musterweg 23<br />
                12345
                Musterhausen<br />
                E-Mail e.muster@domain.de<br />
                Tel 049-098-764512-0<br />
                Fax 049-098-764512-99<br />

                Gewerberegister Musterstadt,  987654321
                <br />
                Ust-IdNr: DE113344990
                <br />
                W-IdNr: DE 22334455
                <br />
                <br />

                <strong>{t("liability")}</strong>
                <br />
                <br />
                {t("libiality_text")}

                <br />
                <br />
                <strong>{t("data_privacy")}</strong>
                <br />
                <br />
                {t("data_privacy_text")}
                <br />
                <br />
                <strong>
                {t("google_ad")}</strong>
                <br />
                <br />
                {t("google_ad_text")}
                <br />
                <br />
                <i><a href="http://www.agb.de">{t("text_foot")}</a> </i>
            </div>
        </div>
    );
};

export default Impressum;
