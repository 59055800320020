import { SocialType } from "shared/SocialsShare/SocialsShare";
import React, { FC } from "react";
import facebook from "images/socials/facebook.svg";
import twitter from "images/socials/twitter.svg";
import telegram from "images/socials/telegram.svg";
import youtube from "images/socials/youtube.svg";
import whatsapp from "images/socials/whatsapp.svg";
import gmail from "images/socials/gmail.svg";
import HelpModalShow from "components/HelpModalShow";
import InprogressModal from "components/InprogressModal";

export interface SocialsList1Props {
  className?: string;
}

const socials: SocialType[] = [
  { name: "Facebook", icon: facebook, href: "#" },
  { name: "Youtube", icon: youtube, href: "#" },
     { name: "Telegram", icon: telegram, href: "#" },
    { name: "Twitter", icon: twitter, href: "#" }, 
  /*{ name: "Whatsapp", icon: whatsapp, href: "#" },
  { name: "Gmail", icon: gmail, href: "#" },*/
];

const SocialsList1: FC<SocialsList1Props> = ({ className = "space-y-3" }) => {

  const [showInprogressModal, setShowInprogressModal] = React.useState(false);

  const renderItem = (item: SocialType, index: number) => {
    return (
      <a
        className="flex items-center text-2xl text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white leading-none space-x-2 group"
        key={index}
        onClick={() => setShowInprogressModal(true)}
      >
        <div className="flex-shrink-0 w-5  cursor-pointer">
          <img src={item.icon} alt="" />
        </div>
        <span className="hidden lg:block text-sm  cursor-pointer">{item.name}</span>
      </a>
    );
  };

  return (
    <>
      <div className={`nc-SocialsList1 ${className}`} data-nc-id="SocialsList1">
        {socials.map(renderItem)}
      </div>
      <InprogressModal
        show={showInprogressModal}
        onCloseModalQuickView={() => setShowInprogressModal(false)}
      />
    </>
  );
};

export default SocialsList1;
