import ButtonPrimary from "shared/Button/ButtonPrimary";
import React from "react";
import { Helmet } from "react-helmet";
import NcImage from "shared/NcImage/NcImage";
import emailVerified from "images/email_verified.png";

const PageEmailVerified: React.FC = () => (
  <div className="nc-Page404">
    <Helmet>
      <title>Email Verified || DoDo Shop</title>
    </Helmet>
    <div className="container relative pt-5 pb-16 lg:pb-20 lg:pt-5">
      {/* HEADER */}
      <header className="text-center max-w-2xl mx-auto space-y-2">
        <NcImage src={emailVerified} />
        <span className="block text-sm text-neutral-800 sm:text-base dark:text-neutral-200 tracking-wider font-medium">
          YOUR EMAIL WAS SUCCESFULLY VERIFIED.{" "}
        </span>
        <div className="pt-8">
          <ButtonPrimary href="/login">Go to Login Page</ButtonPrimary>
        </div>
      </header>
    </div>
  </div>
);

export default PageEmailVerified;
