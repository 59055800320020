import ButtonPrimary from "shared/Button/ButtonPrimary";
import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import NcImage from "shared/NcImage/NcImage";
import forgotPass from "images/forgot_password.png";
import { Link, useHistory } from "react-router-dom";
import { resent } from "services/AuthServices";
import { Resolver, useForm } from "react-hook-form";
import Input from "shared/Input/Input";
import { forgot, resetP } from "services/UserServices";
import CustomAlert from "components/CustomAlert";
import toast from "react-hot-toast";

type forgotValues = {
  email: string;
  password: string;
  password_confirmation: string;
  token: string;
};

const resolver: Resolver<forgotValues> = async (values) => {
  return {
    values: values.email ? values : {},
    errors: !values.email
      ? {
        email: {
          type: 'required',
          message: 'This is required.',
        },
      }
      : {},
  };
};

const ResetPassword: React.FC = () => {

  const history = useHistory();
  const { register, handleSubmit, reset, formState: { errors } } = useForm<forgotValues>({ resolver });

  const [passwordType, setPassWordType] = useState('password');
  const [isOpen, setIsOpen] = useState(false);

  const showAlert = (type: any, message: string) => {
    toast.custom(
      (t) => (
        <CustomAlert
          show={t.visible}
          type={type}
          message={message}
        />
      ),
      { position: "top-right", id: "nc-product-notify", duration: 3000 }
    );
  };

  const resetPassword = async (data: any) => {
    try {
      let result = await resetP(data);
      showAlert("success", "Password reset successfuly !")
      reset();
      history.push("/login");
      localStorage.removeItem("link_to_redirect");
    } catch (error: any) {
      showAlert("error", error.message);
      localStorage.removeItem("link_to_redirect");
    }
  };

  const keepFocus = (element: string) => {

    let input = document.getElementById(element);
    input?.focus();
  }

  const togglePassword = () => {
    setIsOpen(!isOpen)
    if (passwordType === "password") {
      setPassWordType("text");
      keepFocus('password')
    } else {
      setPassWordType("password");
      keepFocus('password')
    }
  }

  useEffect(() => {
    if(localStorage.getItem("pass_through_home")){
      localStorage.removeItem("pass_through_home");
    }else{
      localStorage.setItem("link_to_redirect", "/reset-password");
    }
    
  }, []);

  return (
    <div className="nc-Page404">
      <Helmet>
        <title>Forgot Password || DoDo Shop</title>
      </Helmet>
      <div className="container relative pt-5 pb-16 lg:pb-20 lg:pt-5">
        {/* HEADER */}
        <header className="text-center max-w-2xl mx-auto space-y-2">
          {/* <NcImage src={forgotPass} /> */}
          <span className="block text-sm text-neutral-800 sm:text-base dark:text-neutral-200 tracking-wider font-medium">
            REST PASSWORD ?{" "}
          </span>
          <div className="pt-8">
            <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit(resetPassword)}>
              <label className="block">
                <span className="text-neutral-800 dark:text-neutral-200">
                  Your Email
                </span>
                <Input
                  type="email"
                  className="mt-1"
                  {...register("email")}
                />
              </label>
              <label className="block">
                <span className="text-neutral-800 dark:text-neutral-200">
                  New Password
                </span>
                <div style={{ position: 'relative' }}>
                  <Input
                    type={passwordType}
                    className="mt-1  "
                    {...register("password")}
                  />
                  {!isOpen ?
                    <img src="/eye-solid.svg" className="myEyeR" onClick={togglePassword} alt="toggle" />
                    :
                    <img src="/eye-slash-solid.svg" className="myEyeR" onClick={togglePassword} alt="toggle" />
                  }
                </div>
              </label>
              <label className="block">
                <span className="text-neutral-800 dark:text-neutral-200">
                  Confirm New Password
                </span>
                <div style={{ position: 'relative' }}>
                  <Input
                   type={passwordType}
                    className="mt-1"
                    {...register("password_confirmation")}
                  />
                  {!isOpen ?
                    <img src="/eye-solid.svg" className="myEyeR" onClick={togglePassword} alt="toggle" />
                    :
                    <img src="/eye-slash-solid.svg" className="myEyeR" onClick={togglePassword} alt="toggle" />
                  }
                </div>
              </label>
              <label className="block">
                <span className="text-neutral-800 dark:text-neutral-200">
                  Validation Token
                </span>
                <Input
                  type="text"
                  className="mt-1"
                  {...register("token")}
                />
              </label>
              <ButtonPrimary type="submit">Reset Password</ButtonPrimary>
            </form>
          </div>
        </header>
        <div className="max-w-md mx-auto space-y-6 mt-3">
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Go to ? {` `}
            <Link className="text-green-600" to="/login">
              Login Page
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
