import CustomAlert from "components/CustomAlert";
import Label from "components/Label/Label";
import { AuthContext } from "context/AuthContext";
import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { changePassword } from "services/UserServices";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";

type PasswordValues = {
  current_password: string;
  new_password: string;
  confirm_new_password: string;
};

const AccountPass = () => {

  const { t } = useTranslation();
  const { connected, user } = useContext(AuthContext);
  const { register, handleSubmit, reset, formState: { errors } } = useForm<PasswordValues>();
  const [passwordType, setPassWordType] = useState('password');
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (!localStorage.getItem("access_token") && !sessionStorage.getItem("access_token")) {
       history.push("/");
    }
  });

  const showAlert = (type: any, message: string) => {
    toast.custom(
      (t) => (
        <CustomAlert
          show={t.visible}
          type={type}
          message={message}
        />
      ),
      { position: "top-right", id: "nc-product-notify", duration: 3000 }
    );
  };

  const updatePassword = async (data: any) => {
    try {
      const result = await changePassword(user?.id, data);
      showAlert("success", "Password Updated Successfully !")
      reset();
    } catch (error: any) {
      showAlert("error", error.message)
    }
  };

  const keepFocus = (element: string) => {

    let input = document.getElementById(element);
    input?.focus();
  }

  const togglePassword = () => {
    setIsOpen(!isOpen)
    if (passwordType === "password") {
      setPassWordType("text");
      keepFocus('password')
    } else {
      setPassWordType("password");
      keepFocus('password')
    }
  }

  return (
    <div>
      <CommonLayout>
        <div className="space-y-10 sm:space-y-12">
          {/* HEADING */}
          <h2 className="text-2xl sm:text-3xl font-semibold">
            {t("change_password")}
          </h2>
          <form onSubmit={handleSubmit(updatePassword)}>
            {/* <div className=" max-w-xl space-y-6"> */}
            <div className=" max-w-xl">
              <div style={{ position: 'relative' }}>
                <Label>{t("current_password")}</Label>
                <Input type={passwordType} className="mt-1.5" {...register("current_password")} />
              </div>
              {!isOpen ?
                <img src="/eye-solid.svg" className="myEyeRR" onClick={togglePassword} alt="toggle" />
                :
                <img src="/eye-slash-solid.svg" className="myEyeRR" onClick={togglePassword} alt="toggle" />
              }
              <div>
                <Label>{t("new_password")}</Label>
                <Input type={passwordType} className="mt-1.5" {...register("new_password")} />
              </div>
              {!isOpen ?
                <img src="/eye-solid.svg" className="myEyeRR" onClick={togglePassword} alt="toggle" />
                :
                <img src="/eye-slash-solid.svg" className="myEyeRR" onClick={togglePassword} alt="toggle" />
              }
              <div>
                <Label>{t("update_password")}</Label>
                <Input type={passwordType} className="mt-1.5" {...register("confirm_new_password")} />
              </div>
              {!isOpen ?
                <img src="/eye-solid.svg" className="myEyeRR" onClick={togglePassword} alt="toggle" />
                :
                <img src="/eye-slash-solid.svg" className="myEyeRR" onClick={togglePassword} alt="toggle" />
              }
              <div className="pt-2">
                <ButtonPrimary type="submit">{t("update_password")}</ButtonPrimary>
              </div>
            </div>
          </form>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPass;
