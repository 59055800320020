import ButtonPrimary from "shared/Button/ButtonPrimary";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import NcImage from "shared/NcImage/NcImage";
import successImage from "images/success.png";
import CustomAlert from "components/CustomAlert";
import toast from "react-hot-toast";
import { download } from "services/ArticleServices";
import env from "ts-react-dotenv";
import { useHistory } from "react-router-dom";


const REACT_APP_BACKEND_SERVICE: string = (env?.REACT_APP_BACKEND_SERVICE as string);

const InvoicePage: React.FC = () => {

    const [lastPage, setLastPage] = useState<any>();
    const [html, setHTML] = useState <any>({__html: ""});

    const queryParams = new URLSearchParams(window.location.search);

    let uid = queryParams.get('purchaseUid');

    


  
    useEffect(() => {

        async function createMarkup() {

            try {
                let invoice: any = await download(uid);
                return {__html: invoice};
    
              } catch (error: any) {
              }
           }
           createMarkup().then((result: any) => setHTML(result));
  
    }, []);

  


    return (
         <div dangerouslySetInnerHTML={html} />
    );
}

export default InvoicePage;
