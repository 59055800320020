import React, { FC } from "react";

export interface PricesProps {
  className?: string;
  reductionPrice?: number;
  price?: number;
  contentClass?: string;
}

const Price2: FC<PricesProps> = ({
  className = "",
  price = 33,
  reductionPrice = 0,
  contentClass = "py-1 px-2 md:py-1.5 md:px-2.5 ",
}) => {
  return (
      <>
        <div className={`${className}`}>
            <div
                className={`flex items-center border-2 border-green-500 rounded-lg ml-3 ${contentClass}`}
            >
        <span className="text-green-500 !leading-none">
          ${price.toFixed(2)}
        </span>
            </div>
        </div>
      </>
  );
};

export default Price2;
