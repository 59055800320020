import Price2 from "components/Price2";
import { FeatureValue, PRODUCTS } from "data/data";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import CommonLayout from "./CommonLayout";
import { useContext, useEffect, useState } from "react";
import { getAllPurchases } from "../../services/UserServices";
import { AuthContext } from "../../context/AuthContext";
import moment from "moment";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import env from "ts-react-dotenv";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const REACT_APP_MEDIA_URL: string = (env?.REACT_APP_MEDIA_URL as string);

const AccountOrder = () => {
    const { t } = useTranslation();
    const [purchases, setPurchases] = useState < any[] > ([]);
    const [userId, setUserId] = useState < string > ('');
    const [item, setItem] = useState(null);
    const { connected, user, afterLogin } = useContext(AuthContext);
    const [display, setDisplay] = useState(true);
    const [currentClick, setCurrentClick] = useState(-1);

    const history = useHistory();

    useEffect(() => {
      if (!localStorage.getItem("access_token") && !sessionStorage.getItem("access_token")) {
         history.push("/");
      }
    });

    useEffect(() => {
        const retrieveUsersPaginated = async () => {

            let response: any = await getAllPurchases(user?.id, null, 0);

            if (response) {
                let data: any = response.data;
                let arr: any = Object.values(data);
                setPurchases(arr)
            }
        }
        if(user){
            retrieveUsersPaginated();
        }
        
    }, []);

    const showLessOrMore = (id: number) => {
        setDisplay(!display)
        if (currentClick === id) {
            setCurrentClick(-1)
        } else {
            setCurrentClick(id)
        }
    }

    const renderProductItem = (product: any, index: number) => {

        return (
            <div key={index} className="flex py-4 sm:py-7 last:pb-0 first:pt-0">
                <div className="h-24 w-16 sm:w-20 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
                    <img
                        src={REACT_APP_MEDIA_URL + product?.image}
                        alt={product?.image}
                        className="h-full w-full object-cover object-center"
                    />
                </div>

                <div className="ml-4 flex flex-1 flex-col">
                    <div>
                        <div className="flex justify-between ">
                            <div>
                                <h3 className="text-base font-medium line-clamp-1">{product?.article_name}</h3>
                                {product?.feature_values?.map((item: FeatureValue, index: number) => {
                                    return (
                                        <span key={index}>  {item.name} {index + 1 !== product?.feature_values.length ? <span className="mx-2 border-l border-slate-200 dark:border-slate-700 h-4"></span> : ""}</span>
                                    )
                                })}
                            </div>
                            <Price2 price={parseInt(product?.amount.toFixed(2))} className="mt-0.5 ml-2" />
                        </div>
                    </div>
                    <div className="flex flex-1 items-end justify-between text-sm">
                        <p className="text-gray-500 dark:text-slate-400 flex items-center">
                            <span className="hidden sm:inline-block">Qty</span>
                            <span className="inline-block sm:hidden">x</span>
                            <span className="ml-2">{product?.quantity}</span>
                        </p>


                    </div>
                </div>
            </div>
        );
    };

    const renderOrder = () => {
        return (
            <>
                {
                    purchases.length > 0
                    ? (purchases?.map((item, index) =>
                        <div key={index} className="border border-slate-200 dark:border-slate-700 rounded-lg overflow-hidden z-0">
                            <div
                                className="flex flex-col sm:flex-row sm:justify-between sm:items-center p-4 sm:p-8 bg-slate-50 dark:bg-slate-500/5">
                                <div>
                                    <p className="text-lg font-semibold">{item?.transaction_id}</p> <span>Payment method : {item?.payment_method}</span>
                                    <p className="text-slate-500 dark:text-slate-400 text-sm mt-1.5 sm:mt-2">
                                        <span>{moment(item?.created_at).format("YYYY/MM/DD kk:mm:ss")}</span>
                                        <span className="mx-2">·</span>
                                        <span className="text-primary-500">{item?.status}</span>
                                    </p>
                                </div>
                                <div className="mt-3 sm:mt-0">
                                    <ButtonSecondary
                                        sizeClass="py-2.5 px-4 sm:px-6"
                                        fontSize="text-sm font-medium"
                                        onClick={() => showLessOrMore(index)}
                                    >
                                        {currentClick != index ? "Show more" : "Show less" }
                                    </ButtonSecondary>
                                </div>
                                <Link to={{
                                    pathname: '/invoice',
                                    search: '?purchaseUid=' + item?.purchase_uid
                                }}
                                    target="_blank">
                                    <div className="mt-3 sm:mt-0">

                                        <ButtonSecondary
                                            sizeClass="py-2.5 px-4 sm:px-6"
                                            fontSize="text-sm font-medium"
                                        >
                                            Invoice
                                        </ButtonSecondary>
                                    </div>
                                </Link>
                            </div>
                            {currentClick === index &&
                            <div className="border-t border-slate-200 dark:border-slate-700 p-2 sm:p-8 divide-y divide-y-slate-200 dark:divide-slate-700">
                                {item?.purchase_carts?.map(
                                    (item: any, index: number) => renderProductItem(item, index)
                                )}
                            </div>
                            }
                        </div>
                    ))
                        :
                        <div className="border border-slate-200 dark:border-slate-700 rounded-lg overflow-hidden z-0">
                            <div
                                className="flex flex-col sm:flex-row sm:justify-between sm:items-center p-4 sm:p-8 bg-slate-50 dark:bg-slate-500/5">
                                <p className="text-slate-500 dark:text-slate-400 text-sm mt-1.5 sm:mt-2">
                                {t("no_item")} 
                            </p>
                            </div>

                        </div>
                }
            </>
        );
    };

    return (
        <div>
            <CommonLayout>
                <div className="space-y-10 sm:space-y-12">
                    {/* HEADING */}
                    <h2 className="text-2xl sm:text-3xl font-semibold">{t("order_history")}</h2>
                    {renderOrder()}
                </div>
            </CommonLayout>
        </div>
    );
};

export default AccountOrder;
