import React, { FC } from "react";
import {computePrice} from "../constants";

export interface PricesProps {
  className?: string;
  reductionPrice?: number;
  reductionType?: string;
  price?: number;
  contentClass?: string;
}

const Prices: FC<PricesProps> = ({
  className = "",
  price = 33,
  reductionPrice = 0,
  reductionType = "",
  contentClass = "py-1 px-2 md:py-1.5 md:px-2.5 ",
}) => {
  return (
      <>
    <div className={`${className}`}>
      <div
        className={`flex items-center ${reductionPrice > 0 ? 'border-0 ' : 'border-2 border-green-500' } rounded-lg ${contentClass}`}
      >
        <span className={`${reductionPrice > 0 ? 'text-slate-500 line-through' : 'text-green-500' } !leading-none`}>
          {price.toFixed(2)}€
        </span>
      </div>
    </div>

    {reductionPrice > 0 ?
        <div className={`${className}`}>
            <div
                className={`flex items-center border-2 border-green-500 rounded-lg ml-3 ${contentClass}`}
            >
        <span className="text-green-500 !leading-none">
          {/*${(price - reductionPrice).toFixed(2)}*/}
          {computePrice({price: price, reduction_type: reductionType, reduction_price: reductionPrice}).toFixed(2)}€
        </span>
            </div>
        </div> : null
    }
      </>
  );
};

export default Prices;
