export const computePrice = (article:any) =>{
    if(article){
        let myReductionType = article.reduction_type;
        let myReductionPrice = article.reduction_price;
        let price = 0;
        if(myReductionType !== null){
            if(myReductionType === "AMOUNT"){
                price = article.price - myReductionPrice;
                return price;
            }
            if(myReductionType === "PERCENTAGE" && myReductionPrice>0){
                let percentage:number = (myReductionPrice/100);
                price = article.price - (article.price * percentage);
                return price;
            }
        }
        return article.price;
    }
    return 0;
}

export const reductionAmount = (article:any) =>{
    let myReductionType = article.reduction_type;
    let myReductionPrice = article.reduction_price;
    let amount = 0;
    if(myReductionType === "AMOUNT"){
        return myReductionPrice;
    }
    if(myReductionType === "PERCENTAGE"){
        let percentage:number = (myReductionPrice/100);
        return article.price * percentage;
    }
    return amount;
}

export const hastagHighlight = (element:any) => {
    //let hastags = element.match(/(\#[a-z0-9_A-Z]+\b)(?!;)/gm);
    let hastags = element?.match(/(\#[a-z0-9A-Z][a-z0-9_A-Z]+\b)(?!;)/gm);
    if (hastags === null) {
        return element;
    } else {
        let result = element;
        for (let i = 0; i < hastags?.length; i++) {
            result = result.replace(hastags[i], "<span style=\"color:blue\">" + hastags[i] + "</span >");
        }
        return result;
    }
}

export const resizeIframe = () => {
    let iframe:any = document.getElementById("myIframe");
    iframe.style.height = iframe.contentWindow.document.body.scrollHeight + 30 + 'px';
}
