import ButtonPrimary from "shared/Button/ButtonPrimary";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import NcImage from "shared/NcImage/NcImage";
import verifyEmail from "images/verify_email.png";
import { Link, useHistory } from "react-router-dom";
import { resent } from "services/AuthServices";
import CustomAlert from "components/CustomAlert";
import toast from "react-hot-toast";

interface CustomizedState {
    email?: string
}

const PageVerifyEmail: React.FC = () => {

    const history = useHistory();
    const [isLoading, setLoading] = useState(false);
  
    const { email } = history.location.state as CustomizedState;

    const showAlert = (type: any, message: string) => {
      toast.custom(
        (t) => (
          <CustomAlert
            show={t.visible}
            type={type}
            message={message}
          />
        ),
        { position: "top-right", id: "nc-product-notify", duration: 3000 }
      );
    };
  
    const resentLink = async () => {
        setLoading(true);
  
        try {
          let result = await resent(email as string);
          showAlert("success", "Verify email resend !")
    
        } catch (error: any) {
          showAlert("error", error.message);
        }

        setLoading(false);
      };

      return (    
  <div className="nc-Page404">
    <Helmet>
      <title>Verify Email || DoDo Shop</title>
    </Helmet>
    <div className="container relative pt-5 pb-16 lg:pb-20 lg:pt-5">
      {/* HEADER */}
      <header className="text-center max-w-2xl mx-auto space-y-2">
        <NcImage src={verifyEmail} />
        <span className="block text-sm text-neutral-800 sm:text-base dark:text-neutral-200 tracking-wider font-medium">
          YOU MUST VERIFY YOUR EMAIL.{" "}
        </span>
        <div className="pt-8">
          <ButtonPrimary loading={isLoading} type="button" onClick={resentLink}>Resent Email Verification Link</ButtonPrimary>
        </div>
      </header>
      <div className="max-w-md mx-auto space-y-6 mt-3">

          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            New user? {` `}
            <Link className="text-green-600" to="/signup">
              Create an account
            </Link>
          </span>
        </div>
    </div>
  </div>
);
}

export default PageVerifyEmail;
