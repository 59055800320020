import React from "react";
import {Toaster} from "react-hot-toast";
import { Switch, Route, withRouter } from 'react-router-dom';
import MyRouter from "routers/index";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";

const promise = loadStripe("pk_test_51MQT2pKeHcCj15fGjmJhYreLXzKg701UOkjZzn28siy5O0yyPnwl83YblJeW712k4oYWSZFJ5TzQK9J7715nwART00xZpilkVQ");

function App() {

    return (
        <>
            <Elements stripe={promise}>
                <div>
                    <Toaster/>
                </div>
                {/* MAIN APP */}
                <div className="bg-white text-base dark:bg-slate-900 text-slate-900 dark:text-slate-200">
                    <MyRouter/>
                </div>
            </Elements>
        </>
    );
}

export default App;
