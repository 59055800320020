import axios from "axios";

export const getCategory = (id: string | null) => {
    return new Promise((resolve, reject) => {
        axios
            .get(`/category/${id}`)
            .then((response: any) => {
                resolve(response.data.data);
            })
            .catch((error: any) => {
                reject(error.response.data);
            });
    });
};

export function getAllCategories() {
    return new Promise((resolve, reject) => {
        axios
            .get('/categories')
            .then((response: any) => {
                resolve(response.data.data);
            })
            .catch((error: any) => {
                reject(error.response.data);
            });
    });
}

export function getDescentCategories(id: number) {

    return new Promise((resolve, reject) => {
        axios
            .get(`/category/${id}/descent`)
            .then((response: any) => {
                resolve(response.data.data);
            })
            .catch((error: any) => {
                reject(error.response.data);
            });
    });
}

export function getSelectedCategoriesByAss(ass: string | null) {

    return new Promise((resolve, reject) => {
        axios
            .get(`/get/selected/categories/association/${ass}`)
            .then((response: any) => {
                resolve(response.data.data);
            })
            .catch((error: any) => {
                reject(error.response.data);
            });
    });
}

export function getSelectedCategories() {

    return new Promise((resolve, reject) => {
        axios
            .get(`/get/selected/categories`)
            .then((response: any) => {
                resolve(response.data.data);
            })
            .catch((error: any) => {
                reject(error.response.data);
            });
    });
}

export function getNumberOfArticlesByAss(id: number, ass: string | null) {

    return new Promise((resolve, reject) => {
        axios
            .get(`/get/number/articles/category/${id}/association/${ass}`)
            .then((response: any) => {
                resolve(response.data.data);
            })
            .catch((error: any) => {
                reject(error.response.data);
            });
    });
}