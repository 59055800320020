import { useState } from 'react'
import { Tab } from '@headlessui/react'

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

export default function Example() {
    let [categories] = useState({
        // Website: [
        //     {
        //         id: 1,
        //         title: 'Does drinking coffee make you smarter?',
        //         link:'',
        //     },
        //     {
        //         id: 2,
        //         title: "So you've bought coffee... now what?",
        //         link:'',
        //     },
        // ],
        Stripe: [
            {
                id: 1,
                title: 'Cookie Policy',
                link:'https://stripe.com/fr-us/legal/cookies-policy',
            },
            // {
            //     id: 2,
            //     title: 'The most innovative things happening in coffee',
            //     link:'',
            // },
        ],
        Paypal: [
            {
                id: 1,
                title: 'PayPal Applicant Notice on Cookies, Web Beacons, and Similar Technologies',
                link:'https://www.paypal.com/us/webapps/mpp/jobs/cookies',
            },
            // {
            //     id: 2,
            //     title: "The worst advice we've ever heard about coffee",
            //     link:'',
            // },
        ],
    })

    return (
        <div className="w-full max-w-md px-2 py-16 sm:px-0">
            <Tab.Group>
                <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
                    {Object.keys(categories).map((category) => (
                        <Tab
                            key={category}
                            className={({ selected }) =>
                                classNames(
                                    'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700',
                                    'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                                    selected
                                        ? 'bg-white shadow'
                                        : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                                )
                            }
                        >
                            {category}
                        </Tab>
                    ))}
                </Tab.List>
                <Tab.Panels className="mt-2">
                    {Object.values(categories).map((posts, idx) => (
                        <Tab.Panel
                            key={idx}
                            className={classNames(
                                'rounded-xl bg-white p-3',
                                'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
                            )}
                        >
                            <ul>
                                {posts.map((post) => (
                                    <li
                                        key={post.id}
                                        className="relative rounded-md p-3 hover:bg-gray-100"
                                    >
                                        <h3 className="text-sm font-medium leading-5">
                                            {post.title}
                                        </h3>
                                        <h3 className="text-sm font-medium leading-5">
                                            {post.link}
                                        </h3>

                                        {/*<ul className="mt-1 flex space-x-1 text-xs font-normal leading-4 text-gray-500">*/}
                                        {/*    <li>{post.date}</li>*/}
                                        {/*    <li>&middot;</li>*/}
                                        {/*    <li>{post.commentCount} comments</li>*/}
                                        {/*    <li>&middot;</li>*/}
                                        {/*    <li>{post.shareCount} shares</li>*/}
                                        {/*</ul>*/}

                                        {post.link? <a
                                            target="_blank"
                                            href={post.link}
                                            className={classNames(
                                                'absolute inset-0 rounded-md',
                                                'ring-blue-400 focus:z-10 focus:outline-none focus:ring-2'
                                            )}
                                        />: null}
                                    </li>
                                ))}
                            </ul>
                        </Tab.Panel>
                    ))}
                </Tab.Panels>
            </Tab.Group>
        </div>
    )
}
