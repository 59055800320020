import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import rightImgDemo from "images/rightLargeImg.png";
import undraw_welcome from "images/undraw_welcome.png";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Logo from "shared/Logo/Logo";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { useHistory } from "react-router-dom";

export interface SectionPromo1Props {
  className?: string;
}

//#F5C80F
const SectionLandingPage: FC<SectionPromo1Props> = ({ className = "" }) => {

  const history = useHistory();

  const saveAsMen = () => {
    localStorage.setItem("start_as", "MEN");
    history.push("/");
  };

  const saveAsWomen = () => {
    localStorage.setItem("start_as", "WOMEN");
    history.push("/");
  };

  return (
    <div
      className={`nc-SectionPromo1 relative flex flex-col lg:flex-row items-center ${className}`}
      data-nc-id="SectionPromo1"
    >
      <div className="relative flex-shrink-0 mb-16 lg:mb-0 lg:mr-10 lg:w-2/5">
        <Logo className="w-28" />
        <h2 className="font-semibold text-3xl sm:text-4xl xl:text-5xl 2xl:text-6xl mt-6 sm:mt-10 !leading-[1.2] tracking-tight">
          Welcome to <br /> DoDo Shop
        </h2>
        <span className="block mt-6 text-slate-500 dark:text-slate-400 ">
          Fine articles of all types for both Men & Women
        </span>
        <div className="flex space-x-2 sm:space-x-5 mt-6 sm:mt-12">
          <ButtonPrimary onClick={saveAsMen} className="">
            Start as Men
          </ButtonPrimary>
          <ButtonSecondary
            onClick={saveAsWomen}
            className="border border-slate-100 dark:border-slate-700"
          >
            Start as Women
          </ButtonSecondary>
        </div>
      </div>
      <div className="relative flex-1 max-w-xl lg:max-w-none">
        <NcImage containerClassName="block dark:hidden" src={undraw_welcome} />
        <NcImage
          containerClassName="hidden dark:block"
          src={undraw_welcome}
        />
      </div>
    </div>
  );
};

export default SectionLandingPage;
