import React from "react";
import ReactDOM from "react-dom/client";
//
import "./styles/index.scss";
import "./index.css";
import "./fonts/line-awesome-1.3.0/css/line-awesome.css";
import "rc-slider/assets/index.css";

//
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {Provider} from "react-redux";
import {persistor, store} from "app/store";
import {PersistGate} from "redux-persist/integration/react";
import axios from "axios";
import AuthContextProvider from "context/AuthContext";
import CartContextProvider from "./context/CartContext";
import "./services/i18n";

import env from "ts-react-dotenv";

//const configValue: string = (process.env?.REACT_APP_BACKEND_SERVICE as string);
const configValue: string = (env?.REACT_APP_BACKEND_SERVICE as string);
axios.defaults.baseURL = configValue + "/api";

//axios.defaults.baseURL = "https://test.api.dodo.ntb-entreprise.de/api";

// Request interceptor for API calls
axios.interceptors.request.use(
    async config => {

        let access_token: string | null = "";
        if (localStorage.getItem("access_token")) {
            access_token = localStorage.getItem("access_token");
        }

        if (sessionStorage.getItem("access_token")) {
            access_token = sessionStorage.getItem("access_token");
        }

        config.headers = {
            'Authorization': `Bearer ${access_token}`,
            'Accept': '*'
        }
        return config;
    },
    error => {
        Promise.reject(error)
    });


// Response interceptor for API calls
axios.interceptors.response.use((response) => {
    return response
}, async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
        localStorage.removeItem("access_token");
        sessionStorage.removeItem("access_token");
        window.location.replace('/');
    }
    return Promise.reject(error);
});

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    // <React.StrictMode>
    <AuthContextProvider>
        <CartContextProvider>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <App/>
                </PersistGate>
            </Provider>
        </CartContextProvider>
    </AuthContextProvider>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
