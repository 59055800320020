import React, { FC } from "react";
import { Link } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import LikeButton from "./LikeButton";
import Prices from "./Prices";
import toast from "react-hot-toast";
import { Transition } from "@headlessui/react";
import ModalQuickView from "./ModalQuickView";
import env from "ts-react-dotenv";
import whatsapp from "images/socials/whatsapp.svg";
import gmail from "images/socials/gmail.svg";
import {reductionAmount} from "../constants";


const REACT_APP_MEDIA_URL: string = (env?.REACT_APP_MEDIA_URL as string);

export interface CategoryCardProps {
    className?: string;
    data?: any;
    isLiked?: boolean;
}

const CategoryCard: FC<CategoryCardProps> = ({
    className = "",
    data,
    isLiked,
}) => {
    const {
        name,
        desc,
        img,
        uid
    } = data;
    const [variantActive, setVariantActive] = React.useState(0);
    const [showModalQuickView, setShowModalQuickView] = React.useState(false);
    const start_as: string | null = localStorage.getItem('start_as');

    return (
        <>
                          <Link
                    to={{
                      pathname: "page-collection",
                      search: "?categoryId=" + uid + "&association=" + start_as,
                    }}
                    className={`nc-CardCategory2 ${className}`}
                    data-nc-id="CardCategory2"
                   > 
                    <div
                      className={`flex-1 relative w-full h-0 rounded-2xl overflow-hidden group aspect-w-1 aspect-h-1 bg-orange-50`}
                    >
                      <div className="pt-14">
                        <NcImage
                          containerClassName="w-full h-full flex justify-center"
                          src={REACT_APP_MEDIA_URL + img}
                          className="object-cover rounded-2xl"
                        />
                      </div>
                      <span className="opacity-0 group-hover:opacity-100 absolute inset-0 bg-black bg-opacity-10 transition-opacity"></span>

                    </div>
                    <div className="mt-5 flex-1 text-center">
                        <h2 className="text-base sm:text-lg text-neutral-900 dark:text-neutral-100 font-semibold">
                          {name}
                        </h2>
                        <span className="block mt-0.5 sm:mt-1.5 text-sm text-neutral-500 dark:text-neutral-400">
                          {desc}
                        </span>
                    </div>

                  </Link>
{/*             <div
                className={`nc-ProductCard relative flex flex-col bg-transparent ${className}`}
                data-nc-id="ProductCard"
            >

                <div
                    className="relative flex-shrink-0 bg-slate-50 dark:bg-slate-300 rounded-3xl overflow-hidden z-1 group">
                    <Link to={{
                        pathname: link,
                        search: '?articleId=' + id
                    }} className="block">
                        <NcImage
                            containerClassName="flex aspect-w-11 aspect-h-12 w-full h-0"
                            src={REACT_APP_MEDIA_URL + image?.path}
                            className="object-cover w-full h-full drop-shadow-xl"
                        />
                    </Link>

                </div>

                <div className="space-y-4 px-2.5 pt-5 pb-2.5">

                    <div>
                        <Link to={{
                            pathname: link,
                            search: '?articleId=' + id
                        }} >
                            <h2
                                className={`nc-ProductCard__title text-base font-semibold transition-colors`}
                            >
                                {name}
                            </h2>
                            <p className={`text-sm text-slate-500 dark:text-slate-400 mt-1 `}>
                                {description}
                            </p>
                        </Link>
                    </div>

                    <div className="flex justify-between items-end ">
                        <Prices
                            // price={price}
                            price={parseInt(price.toFixed(2))}
                            reductionPrice={reduction_price}
                            reductionType={reduction_type}
                        />
                        <div className="flex items-center mb-0.5">
                            <a href={`mailto:${"admin@gmail.com"}?subject=${name}&body=${description}`} target="_blank">
                                <div className="flex-shrink-0 w-5 cursor-pointer">
                                    <img src={gmail} alt="" />
                                </div>
                            </a>
                            <a href={`https://wa.me/690201027?text=${name} + ' ' + ${description}`} target="_blank">
                                <div className="flex-shrink-0 w-5 cursor-pointer ml-3">
                                    <img src={whatsapp} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
};

export default CategoryCard;
