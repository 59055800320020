import ButtonPrimary from "shared/Button/ButtonPrimary";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import NcImage from "shared/NcImage/NcImage";
import successImage from "images/success.png";
import CustomAlert from "components/CustomAlert";
import toast from "react-hot-toast";
import { download } from "services/ArticleServices";
import env from "ts-react-dotenv";
import { Link, useHistory } from "react-router-dom";


const REACT_APP_BACKEND_SERVICE: string = (env?.REACT_APP_BACKEND_SERVICE as string);

const PaymentSuccess: React.FC = () => {

    const [lastPage, setLastPage] = useState<any>();

    const queryParams = new URLSearchParams(window.location.search);

    let uid = queryParams.get('purchaseUid');

    const history = useHistory();
  
    useEffect(() => {
  
  
    }, [uid]);

    useEffect(() => {
      if (!localStorage.getItem("access_token") && !sessionStorage.getItem("access_token")) {
         history.push("/");
      }
    });

    const showAlert = (type: any, message: string) => {
        toast.custom(
          (t) => (
            <CustomAlert
              show={t.visible}
              type={type}
              message={message}
            />
          ),
          { position: "top-right", id: "nc-product-notify", duration: 3000 }
        );
      };
  
        const downloadInvoice = async () => {
            /* const link = document.createElement("a");
            link.target = "_blank";
            link.download = "YOUR_FILE_NAME" */
          try {
            let invoice: any = await download(uid);
            //link.href = URL.createObjectURL(invoice);
            //link.click();
            showAlert("success", "Downloaded successfuly !");

          } catch (error: any) {
            showAlert("error", error.message);
          }
        };

    return (

  <div className="nc-Page404">
    <Helmet>
      <title>Payment Success || DoDo Shop</title>
    </Helmet>
    <div className="container relative pt-5 pb-16 lg:pb-20 lg:pt-5">
      {/* HEADER */}
      <header className="text-center max-w-2xl mx-auto space-y-2">
        <NcImage src={successImage} />
        <span className="block text-sm text-neutral-800 sm:text-base dark:text-neutral-200 tracking-wider font-medium">
          PAYMENT SUCCESS.{" "}
        </span>
        <p>Please download your receipt.</p>
        <div className="pt-8">
          {/* <a href={REACT_APP_BACKEND_SERVICE + "/invoice/purchase/" + uid} target="_blank"> */}
          <Link to={{
                            pathname: '/invoice',
                            search: '?purchaseUid=' + uid
                        }} 
                        target="_blank">
          <ButtonPrimary >Download Receipt</ButtonPrimary>
          </Link>
          {/* </a> */}
        </div>
        <div className="pt-8">
          <ButtonPrimary href="/">Continue Shopping</ButtonPrimary>
        </div>
      </header>
    </div>
  </div>
    );
}

export default PaymentSuccess;
