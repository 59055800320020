import React, {FC, useEffect, useRef} from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import NcModal from "shared/NcModal/NcModal";
import {ExclamationTriangleIcon} from "@heroicons/react/24/outline";
import Example2 from "./Examples2";

export interface ModalTransferTokenProps {
    show: boolean;
    onCloseModalTransferToken: () => void;
}

const ModalTransferToken: FC<ModalTransferTokenProps> = ({
                                                             show,
                                                             onCloseModalTransferToken,
                                                         }) => {
    const textareaRef = useRef(null);

    useEffect(() => {
        if (show) {
            setTimeout(() => {
                const element: HTMLTextAreaElement | null = textareaRef.current;
                if (element) {
                    (element as HTMLTextAreaElement).focus();
                    (element as HTMLTextAreaElement).setSelectionRange(
                        (element as HTMLTextAreaElement).value.length,
                        (element as HTMLTextAreaElement).value.length
                    );
                }
            }, 400);
        }
    }, [show]);

    const toggleModal = (e: any) => {
        localStorage.setItem("store_cookie", e.target.value);
        onCloseModalTransferToken();
    }

    const renderContent = () => {
        return (

            <>
                <ExclamationTriangleIcon className="h-10 w-10 text-yellow-300"
                                         aria-hidden="true"/><h3
                className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
                Cookies policy
            </h3>

                <span className="text-sm">
                Dodo Shop needs only necessary cookies to work properly. Those informations will be used
                    on your device and in accordance with
                                                    our Cookie Policy.
        </span>
                <Example2/>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
                        onClick={(e) => toggleModal(e)}
                        value="all_cookies"
                    >
                        Accept
                    </button>
                    <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-blue-300 hover:bg-blue-50 sm:mt-0 sm:w-auto"
                        onClick={onCloseModalTransferToken}
                       // ref={cancelButtonRef}
                        value="necessary_cookies"
                    >
                        Cancel
                    </button>
                </div>
            </>
        );
    };

    const renderTrigger = () => {
        return null;
    };

    return (
        <NcModal
            isOpenProp={show}
            onCloseModal={onCloseModalTransferToken}
            contentExtraClass="max-w-lg"
            renderContent={renderContent}
            renderTrigger={renderTrigger}
            modalTitle=""
        />
    );
};

export default ModalTransferToken;
