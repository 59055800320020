import axios from "axios";

export function getArticles(ass: string | null) {
    return new Promise((resolve, reject) => {
        axios
            .get(`/get/articles/${ass}`)
            .then((response: any) => {
                resolve(response.data.data);
            })
            .catch((error: any) => {
                reject(error.response.data);
            });
    });
}


export function getSelectedArticles(ass: string | null) {

    return new Promise((resolve, reject) => {
        axios
            .get(`/get/selected/articles/${ass}`)
            .then((response: any) => {
                resolve(response.data.data);
            })
            .catch((error: any) => {
                reject(error.response.data);
            });
    });
}

export function getOneArticle(id: string | null) {
    return new Promise((resolve, reject) => {
        axios
            .get(`/article/${id}`)
            .then((response: any) => {
                resolve(response.data.data);
            })
            .catch((error: any) => {
                reject(error.response.data);
            });
    });
}

export function getArticlesByCategoryAndAssociation(id: string | null, ass: string | null) {
    return new Promise((resolve, reject) => {
        axios
            .get(`/articles/category/${id}/association/${ass}`)
            .then((response: any) => {
                resolve(response.data.data);
            })
            .catch((error: any) => {
                reject(error.response.data);
            });
    });
}

export function getArticlesByFilter(keyWord: string, rangePrices: number[], association: string | null, cat_ids: number[], feature_value_ids: number[], pagesize: number | null) {
    let data = {key_word: keyWord,
                min_price: rangePrices[0],
                max_price: rangePrices[1],
                association: association,
                category_ids: cat_ids,
                feature_value_ids: feature_value_ids,
                pagesize: pagesize};

    return new Promise((resolve, reject) => {
        axios
            .post(`/articles/filter`, data)
            .then((response: any) => {
                resolve(response.data.data);
            })
            .catch((error: any) => {
                reject(error.response.data);
            });
    });
}

export function addToCart(user_id: number, variant_id: number, price: number, quantity: number) {
    return new Promise((resolve, reject) => {
        let formData = new FormData();

        //formData.append("amount", (price * quantity).toString());
        formData.append("amount", (price).toString());
        formData.append("quantity", quantity.toString());

        axios
            .post(`/add/variant/${variant_id}/cart/user/${user_id}`, formData)
            .then((response: any) => {
                resolve(response.data.data);
            })
            .catch((error: any) => {
                reject(error.response.data);
            });
    });
}

export function getPaginatedArticles(id: string | null, ass: string | null, page: number | null, page_size: number | null) {

    if (page !== undefined) {
        return new Promise((resolve, reject) => {
            axios
                .get(`/articles/category/${id}/association/${ass}/pagesize/${page_size}?page=${page}`)
                .then((response: any) => {
                    resolve(response.data.data);
                })
                .catch((error: any) => {
                    reject(error.response.data);
                });
        });
    } else {
        return new Promise((resolve, reject) => {
            axios
                .get(`/articles/category/${id}/association/${ass}/pagesize/${page_size}`)
                .then((response: any) => {
                    resolve(response.data.data);
                })
                .catch((error: any) => {
                    reject(error.response.data);
                });
        });
    }
}

export function purchaseCart(user_id: number, orderTotal: any, isShipped: boolean, currentUser: any, mothodActive: string, paypalEmail: string) {

    let data = {user_id: user_id,
        order_total: orderTotal,
        is_shipped: isShipped,
        last_name : currentUser.last_name,
        first_name : currentUser.first_name,
        city : currentUser.city,
        country : currentUser.country,
        address : currentUser.address,
        email : currentUser.email,
        phone : currentUser.phone,
        payment_method: mothodActive,
        paypal_email: paypalEmail};

    return new Promise((resolve, reject) => {
        axios
            .get(`/payment`, { params: data})
            .then((response: any) => {
                resolve(response.data.data);
            })
            .catch((error: any) => {
                reject(error.response.data);
            });
    });
}

export function getCarts(user_id: number) {
    return new Promise((resolve, reject) => {
        axios
            .get(`/get/carts/user/${user_id}`)
            .then((response: any) => {
                resolve(response.data.data);
            })
            .catch((error: any) => {
                reject(error.response.data);
            });
    });
}

export function clearCart(user_id: number) {
    return new Promise((resolve, reject) => {
        axios
            .delete(`/carts/user/${user_id}`)
            .then((response: any) => {
                resolve(response.data.data);
            })
            .catch((error: any) => {
                reject(error.response.data);
            });
    });
}

export function removeFromCart(variant_id : number, user_id: number) {
    return new Promise((resolve, reject) => {

        axios
            .delete(`/variant/${variant_id}/cart/user/${user_id}`)
            .then((response: any) => {
                resolve(response.data.data);
            })
            .catch((error: any) => {
                reject(error.response.data);
            });
    });
}

export function updateQuantity(user_id: number, variant_id: number|undefined, quantity: number) {
    return new Promise((resolve, reject) => {
        let formData = {quantity: quantity.toString()};

        axios
            .post(`/add/variant/${variant_id}/cart/user/${user_id}/quantity`, formData)
            .then((response: any) => {
                resolve(response.data.data);
            })
            .catch((error: any) => {
                reject(error.response.data);
            });
    });
}

export function download(id: string | null) {
    return new Promise((resolve, reject) => {
        axios
            .get(`/invoice/purchase/${id}`, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((response: any) => {
                resolve(response.data.data);
            })
            .catch((error: any) => {
                reject(error.response.data);
            });
    });
}

export function createPaymentIntent(data: any) {
    return new Promise((resolve, reject) => {

        axios
            .post(`/create/payment-intent`, data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then((response: any) => {
                resolve(response.data);
            })
            .catch((error: any) => {
                reject(error.response);
            });
    });
}
export function confirmPaymentIntent(data: any) {
    return new Promise((resolve, reject) => {

        axios
            .post(`/confirm/payment-intent`, data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then((response: any) => {
                resolve(response.data.data);
            })
            .catch((error: any) => {
                reject(error.response);
            });
    });
}

export function retrievePaymentIntent(data: any) {
    return new Promise((resolve, reject) => {

        axios
            .post(`/retrieve/payment-intent`, data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then((response: any) => {
                resolve(response.data);
            })
            .catch((error: any) => {
                reject(error.response);
            });
    });
}


