import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLinkFooter } from "data/types";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import HelpModalShow from "components/HelpModalShow";
import TrackOrderModal from "components/TrackOrderModal";
import DeliveryAndReturnModal from "components/DeliveryAndReturnModal";
import InprogressModal from "components/InprogressModal";
import { useTranslation } from "react-i18next";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  name: string;
  menus: CustomLinkFooter[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "5",
    title: "HELP AND INFORMATION",
    name: "footer_help_and_information",
    menus: [
      { href: "#", label: "Help", name: "footer_help", modal: "help", },
      { href: "#", label: "Track order", name: "footer_track_order", modal: "track_order" },
      { href: "#", label: "Delivery & returns", name: "footer_devivery_returns", modal: "delivery_returns" },
      //{ href: "#", label: "Sitemap" },
    ],
  },
  {
    id: "1",
    title: "ABOUT DODO",
    name: "footer_help",
    menus: [
      { href: "/about", label: "About us", name: "footer_about_us", modal: "link"},
      { href: "/faq", label: "FAQ", name: "FAQ", modal: "link" },
      //{ href: "#", label: "Pricing" },
      //{ href: "#", label: "Security" },
    ],
  },
  {
    id: "2",
    title: "MORE FROM DODO",
    name: "footer_about_dodo",
    menus: [
      { href: "#", label: "Mobile and DODO apps", name: "footer_mobile", modal: "in_progress" },
      { href: "#", label: "DODO Marketplace", name: "footer_marketplace", modal: "in_progress" },
      { href: "#", label: "Refer A freind", name: "footer_refer", modal: "in_progress" },
      //{ href: "#", label: "Learn design" },
    ],
  },
  {
    id: "4",
    title: "TERMS AND CONDITIONS",
    name: "footer_terms",
    menus: [
      { href: "/agb", label: "AGB", name: "footer_agb", modal: "link" },
      { href: "/impressum", name: "footer_impressum", label: "Impressum", modal: "link" },
      //{ href: "#", label: "Contributing" },
      //{ href: "#", label: "API Reference" },
    ],
  },
];

const Footer: React.FC = () => {
  const { t } = useTranslation();
  const [showHelpModal, setShowHelpModal] = React.useState(false);
  const [showTrackOrderModal, setShowTrackOrderModal] = React.useState(false);
  const [showDeliveryAndReturnModal, setShowDeliveryAndReturnModal] = React.useState(false);
  const [showInprogressModal, setShowInprogressModal] = React.useState(false);

  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {t(menu.name)}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              {item.modal == "help" && <a
                key={index}
                className="text-neutral-6000 cursor-pointer dark:text-neutral-300 hover:text-black dark:hover:text-white"
                onClick={() => setShowHelpModal(true)}
                rel="noopener noreferrer"
              >
                {t(item.name)}
              </a>}
              {item.modal === "track_order" && <a
                key={index}
                className="text-neutral-6000 cursor-pointer dark:text-neutral-300 hover:text-black dark:hover:text-white"
                onClick={() => setShowTrackOrderModal(true)}
                rel="noopener noreferrer"
              >
                {t(item.name)}
              </a>}
              {item.modal === "delivery_returns" && <a
                key={index}
                className="text-neutral-6000 cursor-pointer dark:text-neutral-300 hover:text-black dark:hover:text-white"
                onClick={() => setShowDeliveryAndReturnModal(true)}
                rel="noopener noreferrer"
              >
                {t(item.name)}
              </a>}
              {item.modal === "in_progress" && <a
                key={index}
                className="text-neutral-6000 cursor-pointer dark:text-neutral-300 hover:text-black dark:hover:text-white"
                onClick={() => setShowInprogressModal(true)}
                rel="noopener noreferrer"
              >
                {t(item.name)}
              </a>}
              {item.modal === "link" &&  <Link 
                key={index}
                className="text-neutral-6000 cursor-pointer dark:text-neutral-300 hover:text-black dark:hover:text-white"
                to={item.href}
                rel="noopener noreferrer"
              >
                {t(item.name)}
              </Link>}
              
            </li>
          ))}
        </ul>
      </div>
    );
  };

  let location = useLocation();
  const { pathname } = useLocation();
  // ...
  if (pathname === "/invoice" ) {
    return <></>;
  }

  return (
    <div className="nc-Footer relative py-20 lg:pt-28 lg:pb-24 border-t border-neutral-200 dark:border-neutral-700">
      <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-5 lg:gap-x-10 ">
        <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
          <div className="col-span-2 md:col-span-1">
            <Logo />
          </div>
          <div className="col-span-2 flex items-center md:col-span-3">
            <SocialsList1 className="flex items-center space-x-2 lg:space-x-0 lg:flex-col lg:space-y-3 lg:items-start" />
          </div>
        </div>
        {widgetMenus.map(renderWidgetMenuItem)}
      </div>
      <HelpModalShow
          show={showHelpModal}
          onCloseModalQuickView={() => setShowHelpModal(false)}
      />
      <TrackOrderModal
          show={showTrackOrderModal}
          onCloseModalQuickView={() => setShowTrackOrderModal(false)}
      />
      <DeliveryAndReturnModal
          show={showDeliveryAndReturnModal}
          onCloseModalQuickView={() => setShowDeliveryAndReturnModal(false)}
      />
      <InprogressModal
          show={showInprogressModal}
          onCloseModalQuickView={() => setShowInprogressModal(false)}
      />
    </div>
  );
};

export default Footer;
