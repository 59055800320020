import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import translateToEnglish from "../locales/en/translation.json"
import translateToDeutch from "../locales/de/translation.json"
import translateToFrench from "../locales/fr/translation.json"

const resources = {
  en: {
    translation: translateToEnglish
  },
    de: {
    translation: translateToDeutch
  },
    fr: {
    translation: translateToFrench
  },
}
i18next
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    lng: `${window.localStorage.getItem("lang")}`,
  });

export default i18next;