import React, { createContext, FC, ReactNode, useContext, useEffect, useState } from "react";
import { getCarts } from "services/ArticleServices";
import { AuthContext } from "./AuthContext";
import {computePrice} from "../constants";

export type CartContextState = {
  orderTotal: number;
  carts: any;
  tax: number;
  afterAddToCart: () => void;
}

const contextDefaultValues: CartContextState = {
    orderTotal: 0,
    tax: 0,
    carts: null,
    afterAddToCart: () => {},
};

export const CartContext = createContext<CartContextState>(
    contextDefaultValues
);

interface Props {
    children?: ReactNode
}

const CartContextProvider: FC<Props> = ({ children }) => {
    const defaultTax = 0.19;
    const { connected, user} = useContext(AuthContext);
    const [carts, setCarts] = useState<any>(null);
    const [tax, setTax] = useState<any>(0);
    const [orderTotal, setOrderTotal] = useState<number>(0);

    useEffect(() => {
        async function fetchUser() {
            let data: any = await getCarts(user?.id);

            let arr: any = Object.values(data);

            let amt = 0;
            let myProducts = [];

            for (let i in arr) {
                let item = arr[i];

                let tempItem = {
                    "id": item.id,
                    "variant_id": item.variant_id,
                    "name": item.article_name,
                    "price": item.amount,
                    "quantity": item.quantity,
                    "image": item.image,
                    "tax": item.tax,
                    "feature_values": item.feature_values,
                    'reduction_type':item.reduction_type,
                    'reduction_price': item.reduction_price
                };

                myProducts.push(tempItem);

                if(item.tax){
                    setTax((item.tax/100).toFixed(2));
                }

                amt = amt + (item.quantity * tempItem.price);
               // amt = amt + (item.quantity);
            }

            if (tax <= 0) {
                setTax(defaultTax);
            }

            setCarts(myProducts);
            setOrderTotal(amt);
        }
        fetchUser();
    }, []);



    const afterAddToCart = async () => {
        if (connected) {
            let data: any = await getCarts(user?.id);
            let arr: any = Object.values(data);

            let amt = 0;
            let myProducts = [];

            for (let i in arr) {
                let item = arr[i];

                let tempItem = {
                    "id": item.id,
                    "variant_id": item.variant_id,
                    "name": item.article_name,
                    "price": item.amount,
                    "quantity": item.quantity,
                    "image": item.image,
                    "feature_values": item.feature_values,
                    'reduction_type':item.reduction_type,
                    'reduction_price': item.reduction_price
                };

                myProducts.push(tempItem);

                if (item.tax) {
                    setTax((item.tax/100).toFixed(2));
                }

                //amt = amt + (parseInt(item.quantity) *  computePrice(tempItem));
                amt = amt + (item.quantity * tempItem.price);
                //amt = amt + (parseInt(item.quantity));
            }

            if(tax <= 0){
                setTax(defaultTax);
            }

            setCarts(myProducts);
            setOrderTotal(amt);
        }
    }

    return (
      <CartContext.Provider
        value={{
          orderTotal,
          carts,
            tax,
          afterAddToCart,
        }}
      >
        {children}
      </CartContext.Provider>
    );
  };

  export default CartContextProvider;
