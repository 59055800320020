import { AuthContext } from "context/AuthContext";
import React, { useContext } from "react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

export interface FaqLayoutProps {
  children?: React.ReactNode;
}

const FaqLayout: FC<FaqLayoutProps> = ({ children }) => {

  const { t } = useTranslation();
  const { connected, user } = useContext(AuthContext);

  return (
    <div className="nc-CommonLayoutProps container">
      <div className="mt-14 sm:mt-20">
        <div className="max-w-4xl mx-auto">
          <div className="max-w-2xl">
            <h2 className="text-3xl xl:text-4xl font-semibold">{t("faqs")}</h2>
            <span className="block mt-4 text-neutral-500 dark:text-neutral-400 text-base sm:text-lg">
              <span className="text-slate-900 dark:text-slate-200 font-semibold">
              </span>{" "}
            </span>
          </div>
          <hr className="mt-10 border-slate-200 dark:border-slate-700"></hr>


          <hr className="border-slate-200 dark:border-slate-700"></hr>
        </div>
      </div>
      <div className="max-w-4xl mx-auto pt-14 sm:pt-26 pb-24 lg:pb-32">
        {children}
      </div>
    </div>
  );
};

export default FaqLayout;
