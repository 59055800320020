
import React, { FC, useEffect, useState } from "react";

import { Helmet } from "react-helmet";

import Heading from "components/Heading/Heading";
import { getAllAgbs, getAllFaqs } from "services/UserServices";
import { useTranslation } from "react-i18next";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ReactLoading from "react-loading";
import {hastagHighlight} from "../../constants";
import {marked} from "marked";

export interface PageAboutProps {
    className?: string;
}

const Agb: FC<PageAboutProps> = ({ className = "" }) => {

    const [faqs, setFaqs] = useState < any[] > ([{}]);
    const [display, setDisplay] = useState(true);
    const [currentClick, setCurrentClick] = useState(-1);
    const [t, i18n] = useTranslation();
    const [isLoading, setLoading] = useState(false);


    useEffect(() => {
        setLoading(true);
        const retrieveFaqs = async () => {

            let data: any = await getAllAgbs();

            if(data){
                let arr: any = Object.values(data);
                setFaqs(arr)
            }
            setLoading(false);
        }

        retrieveFaqs();
    }, []);

    const showLessOrMore = (id: number) => {
        setDisplay(!display)
        if (currentClick === id) {
            setCurrentClick(-1)
        } else {
            setCurrentClick(id)
        }
    }

    return (
        <div
            className={`nc-PageAbout overflow-hidden relative ${className}`}
            data-nc-id="PageAbout"
        >
            <Helmet>
                <title>AGB || DoDo Shop</title>
            </Helmet>

            <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">

                 <div className="nc-SectionFounder relative">
                    <Heading
                        desc="Here all AGB"
                    >
                        AGB
                    </Heading>

                     {isLoading ?
                         <div style={{
                             position: 'absolute',
                             height: '100px',
                             width: '50px',
                             top: '50%',
                             left: '50%',
                             marginBottom: '50px',
                             marginLeft: '-50px',
                             marginTop: '100px',
                             backgroundSize: ' 100%'
                         }}>
                             <ReactLoading width={50} type={"spin"} color="yellow"/>
                         </div>
                         :
                         <>
                         <div className="space-y-10 sm:space-y-12">
                             {
                                 faqs.length >= 1 ?
                                     (faqs?.map((item: any, index: number) =>

                                         <div key={index}
                                              className="border border-slate-200 dark:border-slate-700 rounded-lg overflow-hidden z-0">
                                             <div
                                                 className="flex flex-col sm:flex-row sm:justify-between sm:items-center p-4 sm:p-8 bg-slate-50 dark:bg-slate-500/5">
                                                 <div>
                                                     {i18n.language === "en" && <span>
                                                          {(item?.question_en !== 'undefined' && item?.question_en !== undefined && item?.question_en !== null) ? <div
                                                              dangerouslySetInnerHTML={{
                                                                  __html: marked(hastagHighlight(item?.question_en)),
                                                              }}
                                                          ></div> : ' '}
                                                         </span>}
                                                     {i18n.language === "fr" && <span>
                                                         {(item?.question_fr !== 'undefined' && item?.question_fr !== undefined && item?.question_fr !== null) ? <div
                                                             dangerouslySetInnerHTML={{
                                                                 __html: marked(hastagHighlight(item?.question_fr)),
                                                             }}
                                                         ></div> : ' '}
                                                     </span>}
                                                     {i18n.language === "de" && <span>
                                                         {(item?.question_de !== 'undefined' && item?.question_de !== undefined && item?.question_de !== null) ? <div
                                                             dangerouslySetInnerHTML={{
                                                                 __html: marked(hastagHighlight(item?.question_de)),
                                                             }}
                                                         ></div> : ' '}</span>}
                                                 </div>
                                                 <div className="mt-3 sm:mt-0">
                                                     <ButtonSecondary
                                                         sizeClass="py-2.5 px-4 sm:px-6"
                                                         fontSize="text-sm font-medium"
                                                         onClick={() => showLessOrMore(index)}
                                                     >
                                                         {currentClick != index ? "Show more" : "Show less"}
                                                     </ButtonSecondary>
                                                 </div>
                                             </div>
                                             {currentClick === index &&
                                                 <div
                                                     className="border-t border-slate-200 dark:border-slate-700 p-2 sm:p-8 divide-y divide-y-slate-200 dark:divide-slate-700">

                                                     {i18n.language === "en" && <p>

                                                         {(item?.answer_en !== 'undefined' && item?.answer_en !== undefined && item?.answer_en !== null) ?
                                                             <iframe srcDoc={marked(hastagHighlight(item?.answer_en))} scrolling="yes"></iframe>
                                                             /*<div
                                                             dangerouslySetInnerHTML={{
                                                                 __html: marked(hastagHighlight(item?.answer_en)),
                                                             }}
                                                         ></div> */
                                                             : ' '}
                                                     </p>}
                                                     {i18n.language === "fr" && <p>
                                                         {(item?.answer_fr !== 'undefined' && item?.answer_fr !== undefined && item?.answer_fr !== null) ?
                                                             <iframe srcDoc={marked(hastagHighlight(item?.answer_fr))} scrolling="yes"></iframe>
                                                            : ' '}
                                                     </p>}
                                                     {i18n.language === "de" && <p>
                                                         {(item?.answer_de !== 'undefined' && item?.answer_de !== undefined && item?.answer_de !== null) ?
                                                             <iframe srcDoc={marked(hastagHighlight(item?.answer_de))} scrolling="yes"></iframe> : ' '}
                                                         </p>}
                                                 </div>
                                             }

                                         </div>

                                             ))
                                     :
                                     ""
                             }
                         </div>
                         </>
                         }

                </div> 
            </div>
        </div>
    );
};

export default Agb;
