
import React, { FC, useContext, useEffect, useState } from "react";

import { Helmet } from "react-helmet";

import Heading from "components/Heading/Heading";
import { AuthContext } from "context/AuthContext";
import { useHistory, useLocation } from "react-router-dom";
import CustomAlert from "components/CustomAlert";
import toast from "react-hot-toast";
import { socialCallback } from "services/AuthServices";

export interface FacebookProps {
    className?: string;
}

const FacebookCallback: FC<FacebookProps> = ({ className = "" }) => {


    const { connected, user, afterLogin } = useContext(AuthContext);
    const history = useHistory();
    const location = useLocation();

    const showAlert = (type: any, message: string) => {
        toast.custom(
            (t) => (
                <CustomAlert
                    show={t.visible}
                    type={type}
                    message={message}
                />
            ),
            { position: "top-right", id: "nc-product-notify", duration: 3000 }
        );
    };

    useEffect(() => {

        const search = new URLSearchParams(window.location.search);
        const fetchSocialCallback = async () => {

            try {
                let data = await socialCallback(location.search, 'facebook');
                afterLogin();
                history.push("/");
    
            } catch (error: any) {
                if (error.status === 403) {
                    let email = error.data.message;
                    history.push({
                        pathname: '/verify-email',
                        state: { email: email }
                    }
                    );
                } else {
                    showAlert("error", error.data.message)
                }
            }
        }
        fetchSocialCallback();

    }, []);

    return (
        <div
            className={`nc-PageAbout overflow-hidden relative ${className}`}
            data-nc-id="PageAbout"
        >
            <Helmet>
                <title>Facebook Callback || DoDo Shop</title>
            </Helmet>


        </div>
    );
};

export default FacebookCallback;
