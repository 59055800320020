import { Transition } from "@headlessui/react";
import Prices from "components/Prices";
import { PRODUCTS } from "data/data";
import React, { FC } from "react";

interface Props {
  show: boolean;
  message: string;
  type?: "default" | "warning" | "info" | "success" | "error";
}

const CustomAlert: FC<Props> = ({
  show,
  message,
  type = "default",
}) => {

    let classes = "";
  switch (type) {
    case "default":
      classes += " bg-white";
      break;
    case "info":
      classes += " bg-blue-300";
      break;
    case "success":
      classes += " bg-green-600";
      break;
    case "error":
      classes += " bg-red-500";
      break;
    case "warning":
      classes += " bg-orange-300";
      break;
    default:
      break;
  }

  const renderProductCartOnNotify = () => {
    return (
      <div className="flex">
        <div className="ml-4 flex flex-1 flex-col">
          <div className="flex flex-1 items-end justify-between text-sm">
            <p className="text-white-500 dark:text-slate-400">{message}</p>

            <div className="flex">
              <button
                type="button"
                className="font-medium text-primary-6000 dark:text-primary-500 "
              >
                {/* close */}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Transition
      appear
      show={show}
      className={`p-4 max-w-md w-full dark:bg-slate-800 shadow-lg rounded-2xl pointer-events-auto ring-1 ring-black/5 dark:ring-white/10 text-white white:text-slate-50 ${classes}`}
      enter="transition-all duration-150"
      enterFrom="opacity-0 translate-x-20"
      enterTo="opacity-100 translate-x-0"
      leave="transition-all duration-150"
      leaveFrom="opacity-100 translate-x-0"
      leaveTo="opacity-0 translate-x-20"
    >
      {/*<p className="block text-base font-semibold leading-none">*/}
      {/*  Alert*/}
      {/*</p>*/}
      {/*<hr className=" border-slate-200 dark:border-slate-700 my-4" />*/}
      {renderProductCartOnNotify()}
    </Transition>
  );
};

export default CustomAlert;
